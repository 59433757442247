import { FlexContainer } from "@/uikit/container";
import React from "react";
import { Typography } from "@/uikit/Typography";
import { Link } from "@/blocks/tracking";
import { Svg } from "@/uikit/Svg";

import {
  rootStyle,
  menuContainer,
  menuTitleStyle,
  menuLinkStyle,
  menuLinkTypographyStyle,
  copyrightStyle,
  socialItemsStyle,
  socialLinksContainer,
  feedbackBlockWrapperStyle,
  appNameStyle,
  appDescriptionWrapperStyle,
  titleStyle,
  descriptionStyle,
  actionWrapperStyle,
  menuContainerWrapperStyle,
  socialNameStyle,
  sectionWrapperStyle,
  actionsWrapperStyle,
  arrowForwardStyle,
  socialLinkStyle,
  socialLinksWrapper,
  menuWrapperStyle,
} from "./footer.css";

import { FooterSchema } from "src/schema";
import { buttonFromSchema } from "@/blocks/schema-mappers";
import { BlockContainer } from "@/blocks/base/block-container";
import ArrowForwardSvg from "@/svg/icons/arrow_forward.svg";

export const Footer = (props: FooterSchema) => {
  const { sections, socialLinks, text, copyright, feedbackBlock } = props;
  return (
    <footer>
      <BlockContainer defaultTheme="silver">
        <FlexContainer className={rootStyle} alignItems="start" justifyContent="between">
          <div className={feedbackBlockWrapperStyle}>
            <FlexContainer className={appDescriptionWrapperStyle}>
              <Svg asset={feedbackBlock.icon} />
              <Typography className={appNameStyle} settings={{ tag: "p" }}>
                {feedbackBlock.appName}
              </Typography>
            </FlexContainer>
            <div>
              <Typography className={titleStyle} settings={{ tag: "p" }}>
                {feedbackBlock.title}
              </Typography>
              <Typography className={descriptionStyle} settings={{ tag: "p" }}>
                {feedbackBlock.description}
              </Typography>
              <FlexContainer className={actionsWrapperStyle}>
                {feedbackBlock.actions &&
                  feedbackBlock.actions.map((action, i) => (
                    <div className={actionWrapperStyle} key={i}>
                      {buttonFromSchema(action)}
                    </div>
                  ))}
              </FlexContainer>
            </div>
          </div>
          <FlexContainer className={menuContainerWrapperStyle} padding="default" direction="column" alignItems="end">
            <div className={menuContainer}>
              {sections?.map((section, index) => (
                <div className={sectionWrapperStyle} key={index}>
                  <Typography className={menuTitleStyle}>{section.title}</Typography>
                  <FlexContainer direction="column" alignItems="start" className={menuWrapperStyle}>
                    {section.links?.map((link, index) => (
                      <Link className={menuLinkStyle} key={index} to={link.link} eventName={link.trackingEvent}>
                        <Typography className={menuLinkTypographyStyle}>{link.text}</Typography>
                        <ArrowForwardSvg className={arrowForwardStyle} />
                      </Link>
                    ))}
                  </FlexContainer>
                </div>
              ))}
              <div className={socialLinksContainer}>
                <FlexContainer direction="column" alignItems="start" gap="none" className={socialLinksWrapper}>
                  <Typography className={menuTitleStyle} settings={{ tag: "h6" }}>
                    {text}
                  </Typography>
                  <div className={socialItemsStyle}>
                    {socialLinks?.map(({ href, image, name }, index) => (
                      <a className={socialLinkStyle} key={index} href={href} target="_blank" rel="noreferrer">
                        <Svg asset={image} />
                        <Typography settings={{ tag: "p" }} className={socialNameStyle}>
                          {name}
                        </Typography>
                      </a>
                    ))}
                  </div>
                </FlexContainer>
              </div>
            </div>
            <Typography className={copyrightStyle}>{copyright}</Typography>
          </FlexContainer>
        </FlexContainer>
      </BlockContainer>
    </footer>
  );
};
